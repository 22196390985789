export default function MenuItem({
    children,
    activeTab,
    setActiveTab,
    testId,
}) {
    return (
        <li
            className={
                activeTab === children
                    ? `px-4 py-2 cursor-pointer rounded-md bg-light-secondary`
                    : 'px-4 py-2 cursor-pointer'
            }
            onClick={(e) => setActiveTab(e.target.textContent)}
            data-testid={testId}>
            {children}
        </li>
    )
}
