import PrimaryButton from '../Buttons/PrimaryButton'
import Tooltip from '../Tooltip/Tooltip'
import InfoMessage from '../InfoMessage/InfoMessage'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'
import { updateRP } from '../../utils/utils'
import { useState } from 'react'

export default function UpdateRP({
    characters = [],
    fetchRPs,
    closeModal,
    rpToUpdate,
}) {
    const [postIsLoading, setPostIsLoading] = useState(false)
    const [newRPName, setNewRPName] = useState('')
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [hasError, setHasError] = useState(false)

    const triggerError = () => {
        setHasError(true)
    }

    const handleCharacterChoice = (e) => {
        setSelectedCharacter(e.target.value)
    }

    const modifyRP = async () => {
        setPostIsLoading(true)
        try {
            await updateRP(rpToUpdate, newRPName, selectedCharacter)
            fetchRPs()
            closeModal()
        } catch (_) {
            triggerError()
        } finally {
            setPostIsLoading(false)
        }
    }
    return (
        <div className="text-center m-auto p-4">
            <div className="flex justify-center m-auto mt-4">
                <h3>Modifier un RP suivi</h3>
                <Tooltip tooltipStyle="sm:mt-0 mt-4">
                    <p>
                        Il n'est pas possible de modifier l'URL du rp que vous
                        suivez. Si vous souhaitez modifier son lien, supprimez
                        le rp de vos rps suivis, et suivez le une nouvelle fois.
                    </p>
                </Tooltip>
            </div>
            {hasError && (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue au moment de modifier votre RP.
                        Veuillez ré-essayer.
                    </p>
                </InfoMessage>
            )}
            <div className="my-8">
                <input
                    type="text"
                    name="Lien du RP"
                    placeholder="Lien de mon RP"
                    className="px-8 py-4 mb-4 sm:w-9/12 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary bg-disabled"
                    value={rpToUpdate}
                    disabled
                />
                <input
                    type="text"
                    name="Nom de mon RP"
                    placeholder="Nom de mon RP"
                    className="px-8 py-4 mb-4 sm:w-9/12 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    onChange={(e) => setNewRPName(e.target.value)}
                    value={newRPName}
                />
                <select
                    className="px-8 py-4 mb-4 sm:w-9/12 w-full bg-background rounded-md border border-solid border-border-color focus:outline focus:outline-secondary appearance-none"
                    onChange={handleCharacterChoice}
                    data-testid="character-select">
                    <option value="">Nom du personnage lié au RP</option>
                    {characters.map((item, index) => (
                        <option key={`${item}-${index}`} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                <br />
                <Link
                    onClick={closeModal}
                    to="/personnages"
                    className="text-secondary">
                    Gérer mes personnages
                </Link>
            </div>
            <div className="flex justify-center">
                <PrimaryButton clickFunc={modifyRP}>
                    {postIsLoading ? <ButtonSpinner /> : 'Modifier le RP'}
                </PrimaryButton>
            </div>
        </div>
    )
}
