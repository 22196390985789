import HelpItem from '../../../Components/HelpItem/HelpItem'
import MenuItem from '../../../Components/MenuItem/MenuItem'

import { useState } from 'react'

export default function Help() {
    const [activeTab, setActiveTab] = useState('Rédaction des RPs')
    return (
        <>
            <h1>Aide</h1>
            <div className="flex sm:flex-row flex-col justify-between">
                <section className="sm:mr-8 sm:w-1/4 sm:mb-0 mb-8">
                    <ul>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category">
                            Rédaction des RPs
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category">
                            Gestion des RPs
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category">
                            Gestion des personnages
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category">
                            Statistiques
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="help-category">
                            Gestion du compte
                        </MenuItem>
                    </ul>
                </section>
                <section className="sm:w-3/4">
                    {activeTab === 'Rédaction des RPs' ? (
                        <>
                            <HelpItem title="Enregistrer un brouillon">
                                <p>
                                    Votre brouillon est enregistré
                                    automatiquement toutes les 30 secondes. Vous
                                    pourrez le retrouver depuis plusieurs
                                    appareils différents, à condition d'être
                                    connecté au même compte.
                                </p>
                            </HelpItem>
                            <HelpItem title="Récupérer un brouillon">
                                <p>
                                    Votre dernier brouillon est récupéré
                                    automatiquement au chargement de la page,
                                    mais cela peut prendre quelques secondes.
                                </p>
                            </HelpItem>
                            <HelpItem title="Choisir un personnage">
                                <p>
                                    Vous pouvez choisir le personnage auquel
                                    attribuer le RP en haut de la page. Cela
                                    nous permet ensuite de comptabiliser le RP
                                    dans les statistiques de votre personnage.
                                    Si vous ne choisissez aucun personnage, les
                                    mots seront comptés dans vos statistiques,
                                    mais pas liés à un personnage spécifique.
                                </p>
                            </HelpItem>
                            <HelpItem title="Choisir un design">
                                <p>
                                    La plateforme vous propose plusieurs
                                    templates pour le design de votre rp. Si
                                    votre template inclut des informations
                                    spécifiques (nom du RP, images, ...), vous
                                    pourrez les renseigner après avoir
                                    sélectionné le template. Vous pouvez
                                    également ne choisir aucun template, votre
                                    RP sera alors copié sans design spécifique.
                                </p>
                            </HelpItem>
                            <HelpItem title="Copier le RP">
                                <p>
                                    Une fois que vous avez rédigé votre RP et
                                    sélectionné un design, cliquez sur 'Copier
                                    le RP'. Votre code complet sera copié
                                    automatiquement, il ne vous restera plus
                                    qu'à poster le RP sur votre forum.
                                </p>
                            </HelpItem>
                            <HelpItem title="Poster le RP">
                                <p>
                                    Une fois le RP posté, cliquez sur 'J'ai
                                    posté mon RP'. Cela permettra de prendre en
                                    compte les stats de votre RP sur le site.
                                    Attention : une fois que vous avez cliqué
                                    sur ce bouton, votre brouillon sera
                                    définitivement supprimé.
                                </p>
                            </HelpItem>
                        </>
                    ) : null}
                    {activeTab === 'Gestion des RPs' ? (
                        <>
                            <HelpItem title="Statut du RP">
                                <p>
                                    Pour savoir si un RP est à jour ou non, nous
                                    regardons si la dernière personne qui a
                                    posté dans le RP fait partie de votre liste
                                    de personnages. Si ce n'est pas le cas, nous
                                    considérons que le RP n'est pas à jour.
                                </p>
                            </HelpItem>
                            <HelpItem title="Statut incorrect">
                                <p>
                                    Le statut qui s'affiche peut être incorrect
                                    si vous n'avez pas ajouté votre personnage
                                    sur le site, ou si vous n'avez pas ajouté le
                                    nom exact utilisé sur le forum. Il arrive
                                    aussi que le code ne fonctionne pas sur des
                                    forums qui ont été beaucoup customisés.
                                </p>
                            </HelpItem>
                        </>
                    ) : null}
                    {activeTab === 'Gestion des personnages' ? (
                        <>
                            <HelpItem title="Ajouter un personnage">
                                <p>
                                    Le fait d'ajouter un personnage vous permet
                                    de lui attribuer des RPs, de suivre vos RPs
                                    à jour, et d'avoir des stats pour ce
                                    personnage. Attention toutefois, il est
                                    important d'utiliser le même nom de
                                    personnage sur Airpéger et sur votre forum.
                                </p>
                            </HelpItem>
                            <HelpItem title="Modifier un personnage">
                                <p>
                                    Il n'est pour l'instant pas possible de
                                    modifier un personnage une fois qu'il a été
                                    ajouté. Vous pouvez supprimer un personnage,
                                    puis en créer un nouveau.
                                </p>
                            </HelpItem>
                        </>
                    ) : null}
                    {activeTab === 'Statistiques' ? (
                        <>
                            <HelpItem title="Comprendre les statistiques">
                                <p>
                                    Chaque RP est lié à un personnage, la page
                                    statistiques vous permet de voir le nombre
                                    de RPs et de mots pour chacun de vos
                                    personnages. Pour que les stats d'un RP
                                    soient ajoutés, il faut obligatoirement
                                    cliquer sur 'J'ai posté mon RP' après avoir
                                    publié votre RP.
                                </p>
                            </HelpItem>
                            <HelpItem title='Stats "Aucun personnage"'>
                                <p>
                                    Si vous validez un RP sans avoir choisi de
                                    personnage lié, ces stats seront
                                    comptabilisées dans l'encart 'Aucun
                                    personnage'. Vous ne pouvez pour le moment
                                    pas attribuer un RP à un personnage une fois
                                    le RP publié, mais n'hésitez pas à{' '}
                                    <a href="mailto:airpeger@gmail.com">
                                        me contacter
                                    </a>{' '}
                                    pour que je puisse attribuer votre RP à un
                                    personnage. Dans ce cas, pensez à indiquer :
                                </p>
                                <ul className="list-disc ml-8 mt-4">
                                    <li>La date du RP</li>
                                    <li>Le nom du personnage à attribuer</li>
                                    <li>Le pseudo de votre compte Airpéger</li>
                                </ul>
                            </HelpItem>
                        </>
                    ) : null}
                    {activeTab === 'Gestion du compte' ? (
                        <>
                            <HelpItem title="Modifier son compte">
                                <p>
                                    Vous pouvez modifier votre mot de passe et
                                    votre avatar depuis la page 'Mon compte'. Il
                                    n'est pas possible de modifier votre pseudo
                                    ou l'adresse email liés à votre compte.
                                </p>
                            </HelpItem>
                            <HelpItem title="Mot de passe oublié">
                                <p>
                                    Si vous avez oublié votre mot de passe, vous
                                    pouvez demander à le modifier depuis la page
                                    de connexion. Cliquez sur 'Mot de passe
                                    oublié', puis renseignez l'email de votre
                                    compte. Vous recevrez un email vous
                                    permettant de modifier le mot de passe de
                                    votre compte.
                                </p>
                            </HelpItem>
                        </>
                    ) : null}
                </section>
            </div>
        </>
    )
}
