import classNames from 'classnames'

export default function Tooltip({ children, tooltipStyle }) {
    return (
        <div
            className={classNames(
                tooltipStyle,
                'ml-4 relative group cursor-pointer'
            )}
            data-testid="tooltip">
            <div className="p-2.5 text-xs w-2 h-2 border border-secondary text-secondary rounded-full flex items-center justify-center">
                ?
            </div>
            <div className="bg-background sm:w-72 w-56 z-50 border border-border rounded-md absolute invisible group-hover:visible text-sm p-3 top-8 right-0 sm:right-auto">
                {children}
            </div>
        </div>
    )
}
