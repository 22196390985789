import { useEffect, useState } from 'react'

import { checkRPs } from '../../utils/utils'
import Spinner from '../Spinner/Spinner'
import EditLight from '../../Assets/edit-light.svg'
import EditDark from '../../Assets/edit-dark.svg'
import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

import './RPItem.css'

export default function RPItem({
    rp,
    characters,
    removeRP,
    statusFilters,
    characterFilters,
    openUpdateModal,
}) {
    const { theme } = useTheme()
    const [status, setStatus] = useState()
    const [isLoading, setIsLoading] = useState(true)

    const fetchStatus = async () => {
        try {
            const { data } = await checkRPs(rp.link, characters)
            setStatus(data.message)
            setIsLoading(false)
        } catch (_) {
            setStatus(false)
            setIsLoading(false)
        }
    }

    const showRP = () => {
        if (status) {
            if (characterFilters.length === characters.length) {
                return statusFilters.includes('À jour')
            } else {
                return (
                    statusFilters.includes('À jour') &&
                    characterFilters.includes(rp.character)
                )
            }
        } else if (!status) {
            if (characterFilters.length === characters.length) {
                return statusFilters.includes('Pas à jour')
            } else {
                return (
                    statusFilters.includes('Pas à jour') &&
                    characterFilters.includes(rp.character)
                )
            }
        }
    }

    const deleteRP = () => {
        removeRP(rp.link)
    }

    const openModal = () => {
        openUpdateModal(rp.link)
    }

    useEffect(() => {
        fetchStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        (showRP() || isLoading) && (
            <div className="bg-background text-main flex flex-col sm:flex-row sm:justify-between items-start sm:items-center rounded-md px-8 py-4 my-4">
                <div className="flex flex-col sm:flex-row items-start sm:items-center w-4/5">
                    {!isLoading ? (
                        status ? (
                            <span className="w-80px text-sm text-center text-green border border-solid border-green py-1 bg-light-green rounded-md">
                                À jour
                            </span>
                        ) : (
                            <span className="w-80px text-sm text-center text-red border border-solid border-red py-1 bg-light-red rounded-md">
                                Pas à jour
                            </span>
                        )
                    ) : (
                        <Spinner />
                    )}
                    <p className="sm:mx-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded border border-solid border-border-color w-40 truncate">
                        {rp.character || 'Aucun personnage'}
                    </p>
                    <a
                        href={rp.link}
                        target="_blank"
                        rel="noreferrer"
                        className="sm:ml-4 sm:my-0 my-4 rp-item-link truncate text-sm">
                        {rp.name || rp.link}
                    </a>
                </div>
                <div className="flex">
                    <img
                        className="cursor-pointer edit-icon w-6 h-6 mr-4"
                        onClick={openModal}
                        src={lightThemes.includes(theme) ? EditLight : EditDark}
                        alt="modifier"
                    />
                    <img
                        className="cursor-pointer trash-icon w-6 h-6"
                        onClick={deleteRP}
                        src={lightThemes.includes(theme) ? Trash : TrashDark}
                        alt="supprimer"
                    />
                </div>
            </div>
        )
    )
}
