import React from 'react'
import { useState, useEffect } from 'react'

import RPItem from '../../../Components/RPItem/RPItem'
import Confirmation from '../../../Components/Confirmation/Confirmation'
import Filter from '../../../Components/Filter/Filter'
import { getRPs, getCharacters, deleteRP } from '../../../utils/utils'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import Spinner from '../../../Components/Spinner/Spinner'
import classNames from 'classnames'
import { lightThemes } from '../../../Data/const'

import { useTheme } from '../../../Context/theme'

import arrowDown from '../../../Assets/Arrows/down.svg'
import arrownDownDark from '../../../Assets/Arrows/down-dark.svg'

export default function Tracker({ setModal, modal }) {
    const [allRPs, setAllRPs] = useState([])
    const [characters, setCharacters] = useState([])
    const [charactersLoaded, setCharactersLoaded] = useState()
    const [isLoading, setIsLoading] = useState()
    const [hasError, setHasError] = useState(false)
    const [rpToUpdate, setRpToUpdate] = useState('')
    const [statusFilters, setStatusFilters] = useState(['À jour', 'Pas à jour'])
    const [characterFilters, setCharacterFilters] = useState([])
    const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false)
    const [isCharacterFilterOpen, setIsCharacterFilterOpen] = useState(false)
    const [modalType, setModalType] = useState('add')

    const { theme } = useTheme()

    const fetchRPs = async () => {
        setIsLoading(true)
        try {
            const { data } = await getRPs()
            setAllRPs(data)
        } catch (_) {
            setAllRPs([])
            triggerError()
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCharacters = async () => {
        try {
            const { data } = await getCharacters()
            setCharacters(data)
            setCharacterFilters(data)
        } catch (_) {
            setCharacters([])
            triggerError()
        } finally {
            setCharactersLoaded(true)
        }
    }

    const openAddModal = () => {
        setModalType('add')
        setModal(true)
    }

    const openUpdateModal = (rpLink) => {
        setModalType('update')
        setModal(true)
        setRpToUpdate(rpLink)
    }

    const closeModal = () => {
        setModal(false)
    }

    const triggerError = () => {
        setHasError(true)
    }

    const removeRP = async (rpLink) => {
        try {
            await deleteRP(rpLink)
            fetchRPs()
        } catch (_) {
            triggerError()
        }
    }

    const openStatusFilter = () => {
        setIsStatusFilterOpen(!isStatusFilterOpen)
    }

    const openCharacterFilter = () => {
        setIsCharacterFilterOpen(!isCharacterFilterOpen)
    }

    useEffect(() => {
        fetchRPs()
        fetchCharacters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <h1>Mes RPs</h1>
                <button
                    onClick={openAddModal}
                    className="w-40 py-2 border border-secondary bg-secondary text-secondary-text rounded-md">
                    Ajouter un RP
                </button>
            </div>
            <div className="flex">
                <div className="status-filter-block">
                    <div
                        className="px-4 py-2 w-56 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                        onClick={openStatusFilter}>
                        <p
                            className={
                                statusFilters.length !== 2
                                    ? 'filter-group-active'
                                    : 'filter-group-inactive'
                            }>
                            {statusFilters.length === 2
                                ? 'Tous les statuts'
                                : statusFilters.length === 0
                                ? 'Aucun statut'
                                : statusFilters[0]}
                        </p>
                        <img
                            src={
                                lightThemes.includes(theme)
                                    ? arrowDown
                                    : arrownDownDark
                            }
                            alt="flèche"
                            className={classNames(
                                isStatusFilterOpen ? 'rotate-180' : null,
                                'w-4 h-4 ease-in-out duration-300'
                            )}
                        />
                    </div>
                    {isStatusFilterOpen && (
                        <div className="p-4 w-56 mt-2 filter-group rounded-md border border-solid border-border-color">
                            <Filter
                                filters={statusFilters}
                                setFilters={setStatusFilters}
                                value="À jour"
                            />
                            <Filter
                                filters={statusFilters}
                                setFilters={setStatusFilters}
                                value="Pas à jour"
                            />
                        </div>
                    )}
                </div>
                <div className="ml-3 character-filter-block">
                    <div
                        className="px-4 py-2 w-56 filter-group flex items-center justify-between rounded-md border border-solid border-border-color cursor-pointer focus:outline focus:outline-secondary"
                        onClick={openCharacterFilter}>
                        <p
                            className={
                                characterFilters.length === characters.length
                                    ? 'filter-group-inactive'
                                    : 'filter-group-active'
                            }>
                            {characterFilters.length === characters.length
                                ? 'Tous les personnages'
                                : characterFilters.length === 1
                                ? characterFilters[0]
                                : characterFilters.length === 0
                                ? 'Aucun personnage'
                                : 'Personnages filtrés'}
                        </p>
                        <img
                            src={
                                lightThemes.includes(theme)
                                    ? arrowDown
                                    : arrownDownDark
                            }
                            alt="flèche"
                            className={classNames(
                                isCharacterFilterOpen ? 'rotate-180' : null,
                                'w-4 h-4 ease-in-out duration-300'
                            )}
                        />
                    </div>
                    {isCharacterFilterOpen && (
                        <div className="p-4 w-56 mt-2 filter-group rounded-md border border-solid border-border-color">
                            {characters.map((character, index) => (
                                <Filter
                                    filters={characterFilters}
                                    setFilters={setCharacterFilters}
                                    value={character}
                                    key={`${character}-${index}`}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {hasError ? (
                <InfoMessage type="warning">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            ) : null}
            {!isLoading && charactersLoaded ? (
                allRPs.length > 0 ? (
                    allRPs.map((item, index) => (
                        <RPItem
                            key={`${item}-${index}`}
                            rp={item}
                            characters={characters}
                            fetchRPs={fetchRPs}
                            removeRP={removeRP}
                            statusFilters={statusFilters}
                            characterFilters={characterFilters}
                            openUpdateModal={openUpdateModal}
                        />
                    ))
                ) : (
                    <InfoMessage>
                        Vous n'avez pas encore ajouté de RP à suivre. Ajoutez un
                        RP que vous souhaitez suivre via le formulaire
                        ci-dessus.
                    </InfoMessage>
                )
            ) : (
                <Spinner centered margin />
            )}

            {modal && (
                <Confirmation
                    closeModal={closeModal}
                    fetchRPs={fetchRPs}
                    source={modalType}
                    characters={characters}
                    rpToUpdate={rpToUpdate}
                />
            )}
        </>
    )
}
