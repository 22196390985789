import React from 'react'

export default function DesignCard({
    name,
    image,
    index,
    setSelectedDesign,
    isSelected,
}) {
    const handleClick = () => {
        setSelectedDesign(name)
    }

    return (
        <li
            className={
                isSelected
                    ? 'w-16% rounded-md mb-4 cursor-pointer border-secondary border'
                    : 'w-16% rounded-md mb-4 cursor-pointer border-border-color border'
            }
            onClick={handleClick}>
            <img
                src={image}
                alt="illustration design"
                className="rounded-md p-1"
            />
        </li>
    )
}
